import './../css/title.css';
import React from 'react';

interface Props {
    title: string
}

interface State {
    
}

class Title extends React.Component<Props, State> {
 
    state: State = {
        
    }

    componentDidMount() {
        
    }

    render()
    {
        return (
            <div className='page title'> 
                <div className='content'>
                    <h1>{this.props.title}</h1>
                </div>
            </div>
        );
    }
}

export default Title;