import './../css/contact.css';
import React, { Fragment } from 'react';

interface Props {
    content: any
}

interface State {
    
}

class Contact extends React.Component<Props, State> {
 
    state: State = {
        
    }

    componentDidMount() {
        
    }

    render()
    {
        return (
            <Fragment>
                {this.props.content &&
                    <div id='contact' className='page'> 
                        <div className='content'>
                            <div className='title-intro'>{this.props.content.contactTitleIntro}</div>
                            <div className='title-main'>{this.props.content.contactTitle}</div>
                            <div id='contact-description'>
                                {this.props.content.contactDescription}
                            </div>
                            <a id='contact-email' href={'mailto:' + this.props.content.contactEmail}>{this.props.content.contactEmail}</a>
                            <div id='contact-socials'>
                                {this.props.content.contactSocials.data.map((social: any) => 
                                    <a key={social.id} className='contact-social' href={social.attributes.url} target='_blank'>
                                        <img src={process.env.REACT_APP_STRAPI_URL + social.attributes.icon.data.attributes.url} />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}

export default Contact;