import './../css/menu.css';
import React from 'react';
import Logo from './../img/RocketVulture-Logo.svg';

interface Props {
    content: any,
    setCurrentPage: any,
    currentPage: string
}

interface State {
    hamburger: boolean
}

class Menu extends React.Component<Props, State> {
 
    state: State = {
        hamburger: false
    }

    componentDidMount() {
        
    }

    clickMenuItem = (page: string) => 
    {
        this.props.setCurrentPage(page);
        //this.setState({hamburger: false});
    }

    render()
    {
        return (
            <div id="menu" className={this.state.hamburger ? 'open' : ''}> 
                {this.props.content &&
                    <div>
                        <div className='content'>
                            <div id="menu-hamburger" onClick={() => this.setState({hamburger: !this.state.hamburger})}></div>
                            <a onClick={(event) => {event.preventDefault(); this.clickMenuItem("")}}><img className='menu-logo' src={Logo} alt='Rocket Vulture' /></a>
                            <div id='menu-buttons' className={this.state.hamburger ? 'open' : 'closed'}>
                                <a className='menu-button' href='/' onClick={(event) => {event.preventDefault(); this.clickMenuItem("")}}>About</a>
                                <a className='menu-button' href='/games' onClick={(event) => {event.preventDefault(); this.clickMenuItem("games")}}>Games</a>
                                <a className='menu-button' href='/services' onClick={(event) => {event.preventDefault(); this.clickMenuItem("services")}}>Services</a>
                                <a className='menu-button' href='/team' onClick={(event) => {event.preventDefault(); this.clickMenuItem("team")}}>Team</a>
                                <a className='menu-button' href='/contact' onClick={(event) => {event.preventDefault(); this.clickMenuItem("contact")}}>Contact</a>       
                                <span id="menu-socials">
                                    {this.props.content.menuSocials.data.map((social: any) => 
                                        <a key={social.id} className='menu-social' href={social.attributes.url} target='_blank'>
                                            <img src={process.env.REACT_APP_STRAPI_URL + social.attributes.icon.data.attributes.url} />
                                        </a>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Menu;