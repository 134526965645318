import './../css/home.css';
import React from 'react';

interface Props {
    content: any
}

interface State {
    
}

class Home extends React.Component<Props, State> {
 
    state: State = {
        
    }

    componentDidMount() {
        
    }

    render()
    {
        return (
            <div id='home'> 
                {this.props.content &&
                    <div className='content'>
                        <div id='home-title-intro'>{this.props.content.homeTitleIntro}</div>
                        <div id='home-title'>{this.props.content.homeTitle}</div>
                        <div id='home-title-outro'>{this.props.content.homeTitleOutro}</div>
                        <div id='home-description'>{this.props.content.homeDescription}</div>
                    </div>
                }
            </div>
        );
    }
}

export default Home;