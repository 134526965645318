import './../css/vaf.css';
import React from 'react';

interface Props {
    
}

interface State {
    
}

class Vaf extends React.Component<Props, State> {
 
    state: State = {
        
    }

    componentDidMount() {
        
    }

    render()
    {
        return (
            <div className='page'> 
                <div className='content'>
                    <h1>RAVE MAKER</h1>
                    <iframe width="960" height="540" src="https://www.youtube.com/embed/4buSKd_I_3A?si=uYBGHQuwSo56AMSd" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </div>
        );
    }
}

export default Vaf;