import './../css/team.css';
import React, { Fragment } from 'react';

interface Props {
    content: any
}

interface State {
    
}

class Team extends React.Component<Props, State> {
 
    state: State = {
        
    }

    componentDidMount() {
        
    }

    render()
    {
        return (
            <Fragment>
                {this.props.content &&
                    <div id='team' className='game page' style={{backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + this.props.content.teamBackgroundImage.data.attributes.url + ')'}}> 
                        <div className='content'>
                            <div className='title-intro'>{this.props.content.teamTitleIntro}</div>
                            <div className='title-main'>{this.props.content.teamTitle}</div>
                            <div className='team-members'>
                                {this.props.content.teamMembers.map((member: any) => 
                                    <div key={member.id} className='team-member'>
                                        <img src={process.env.REACT_APP_STRAPI_URL + member.photo.data.attributes.url} />
                                        <div className='team-member-name'>{member.name}</div>
                                        <div className='team-member-title'>{member.title}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}

export default Team;