import React, { Fragment } from 'react';
import './css/style.css';
import axios from 'axios';
import Menu from './components/menu';
import Home from './components/home';
import Game from './components/game';
import Title from './components/title';
import Team from './components/team';
import Contact from './components/contact';

interface Props {
    
}

interface State {
    content: any,
    currentPage: string
}

class App extends React.Component<Props, State> {

    state: State = {
        content: null,
        currentPage: ""
    }

    componentDidMount() {
        this.getContent();
    }

    getContent = () => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/home?populate=deep')
            .then((response: any) => {
                this.setState({
                    content: response.data.data.attributes
                });
            })
            .catch((error: any) => 
            {
                console.log(error.message);
            });
    }

    setCurrentPage = (page: string) => {
        // Set url
        if (!page) page = "";
        //window.history.pushState({}, "", "/" + page); // Set url in browser

        this.scrollToPage(page);
    }

    scrollToPage = (page: string, behavior: string = 'smooth') => {
        // Scroll to the page
        var y = 0;
        const element = document.getElementById(page);
        if (element) y = element.getBoundingClientRect().top + window.scrollY;
        window.scroll({
            top: y,
            behavior: behavior as ScrollBehavior
        });
    }

    render()
    {
        return (
            <div className="App">
                {this.state.content &&
                    <Fragment>
                        <Menu content={this.state.content} currentPage={this.state.currentPage} setCurrentPage={this.setCurrentPage} />
                        <Home content={this.state.content} />
                        <div id='games'>
                            {this.state.content.games.map((game: any) => 
                                <Game key={game.id} game={game} />
                            )}
                        </div>
                        <Title title='Services' />
                        <div id='services'>
                            {this.state.content.services.map((service: any) => 
                                <Game key={service.id} game={service} />
                            )}
                        </div>
                        <Title title='Team' />
                        <Team content={this.state.content} />
                        <Contact content={this.state.content} />
                    </Fragment>
                }
            </div>
        );
    }
}

export default App;
