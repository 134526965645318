import './../css/game.css';
import React, { Fragment } from 'react';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

interface Props {
    game: any
}

interface State {
    
}

class Game extends React.Component<Props, State> {
 
    state: State = {
        
    }

    componentDidMount() {
        Fancybox.bind("[data-fancybox]", {
            Thumbs: {
                autoStart: false,
                type: "classic",
            }
        });
    }

    render()
    {
        return (
            <Fragment>
                {this.props.game &&
                    <div className='game page' style={{backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + this.props.game.backgroundImage.data.attributes.url + ')'}}> 
                        {this.props.game &&
                            <div className='content'>
                                <div className='title-intro'>{this.props.game.titleIntro}</div>
                                <div className='title-main'>{this.props.game.title}</div>
                                <div className='game-media'>
                                    {this.props.game.media.data.map((media: any) => 
                                        <a key={media.id} 
                                            data-fancybox={this.props.game.id + '-fancybox'} 
                                            data-src={process.env.REACT_APP_STRAPI_URL + media.attributes.url} 
                                            href={process.env.REACT_APP_STRAPI_URL + media.attributes.url} target='_blank'
                                        >
                                            <img src={process.env.REACT_APP_STRAPI_URL + media.attributes.url} />
                                        </a>
                                    )}
                                </div>
                                <div className='game-description'>{this.props.game.description}</div>
                                <div className='game-services'>
                                    {this.props.game.services.map((service: any) => 
                                        <span key={service.id} className='game-service'>{service.name}</span>
                                    )}
                                </div>
                                <div className='game-platforms'>
                                    {this.props.game.platforms.map((platform: any) => 
                                        <a key={platform.id} href={platform.url} target='_blank' className='game-platform'>{platform.platform}</a>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                }
            </Fragment>
        );
    }
}

export default Game;